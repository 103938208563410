var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "guide-form-comp" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "应用公司", prop: "companyId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.ruleForm.companyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "companyId", $$v)
                            },
                            expression: "ruleForm.companyId",
                          },
                        },
                        _vm._l(_vm.companyData, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              value: item.companyId,
                              label: item.companyName,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.optionUnit(item.companyName)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "启用参数", prop: "column" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.tableData },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "date",
                              label: "日期",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "vuedraggable",
                                      {
                                        staticClass: "wrapper",
                                        attrs: { animation: 200 },
                                        model: {
                                          value: _vm.tableData,
                                          callback: function ($$v) {
                                            _vm.tableData = $$v
                                          },
                                          expression: "tableData",
                                        },
                                      },
                                      [
                                        _c("el-tag", [
                                          _vm._v(_vm._s(row.name + row.id)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "姓名",
                              width: "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "address", label: "地址" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _c("el-button", { on: { click: _vm.resetForm } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }