import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 *  分页查询
 * @param params
 */
export function getTableList(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/generationRule/page',
    method: 'get',
    params,
  });
}

/**
 *  删除
 * @param params
 */
export function getDelete(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/generationRule/delete',
    method: 'get',
    params,
  });
}

/**
 *  新增/编辑
 * @param params
 */
export function addPageData(params) {
    return request({
      url: envInfo.bgApp.amsPath + '/generationRule/add',
      method: 'post',
      data:params,
    });
  }


/**
 *  获取详情
 * @param params
 */
export function getDetail(params) {
    return request({
      url: envInfo.bgApp.amsPath + '/generationRule/detail',
      method: 'get',
      params,
    });
  }
