<template>
    <div class="guide-form-comp">
        <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-row>
                <el-col>
                    <el-form-item label="应用公司" prop="companyId">
                        <el-select style="width: 100%" v-model="ruleForm.companyId">
                            <el-option v-for="(item, index) in companyData" :value="item.companyId"
                                :label="item.companyName" :key="index"
                                @click.native="optionUnit(item.companyName)"></el-option>
                        </el-select>
                    </el-form-item>

                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <!-- <vuedraggable class="wrapper" v-model="draggableList" :animation="200">
                        <div v-for="(item, index) in draggableList" :key="index">
                            <div>
                                <span>{{ index + 1 }}</span>
                                <span>{{ item.fieldName }}</span>
                            </div>
                        </div>
                    </vuedraggable> -->
                    <el-form-item label="启用参数" prop="column">

                        <el-table :data="tableData" style="width: 100%">

                            <el-table-column prop="date" label="日期" width="180">

                                <template #default="{ row }">
                                    <vuedraggable class="wrapper" v-model="tableData" :animation="200">
                                        <el-tag>{{ row.name + row.id }}</el-tag>
                                    </vuedraggable>

                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="姓名" width="180">
                            </el-table-column>
                            <el-table-column prop="address" label="地址">
                            </el-table-column>

                        </el-table>

                    </el-form-item>

                </el-col>
            </el-row>
            <el-row>
                <el-form-item align="right">
                    <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>确定</el-button>
                    <el-button @click="resetForm">取消</el-button>
                </el-form-item>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {
    addPageData, getDetail, getCompany
} from "@/api/ruge/ams/basicConfig/fixedAssets";
import vuedraggable from "vuedraggable";

import Sortable from 'sortablejs';
export default {
    props: {
        updateId: {
            type: Number,
            default: () => null
        },
    },
    components: {
        vuedraggable
    },
    data() {
        return {
            ruleForm: {
                companyId: null,
                companyName: null,
                column: []
            },
            rules: {
                companyId: [
                    { required: true, message: "应用公司为必填", trigger: "blur" },
                ],
            },
            companyData: [],
            draggableList: [
                { name: "123", fieldName: "公司编号", suffixType: false, isEnable: false },
                { name: "123", fieldName: "编号1", suffixType: false, isEnable: false },
                { name: "123", fieldName: "编号2", suffixType: false, isEnable: false },
                { name: "123", fieldName: "编号3", suffixType: false, isEnable: false },
                { name: "123", fieldName: "编号4", suffixType: false, isEnable: false },
            ],
            tableData: [{
                id: 0,
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                id: 1,
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                id: 2,
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
            }, {
                id: 3,
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }]
        };
    },
    created() {
        if (this.updateId) {
            this.findDetail()
        }
        this.findCompanyList()
    },
    mounted() {

    },
    methods: {
        findCompanyList() {
            getCompany().then((res) => {
                if (res) {
                    this.companyData = res
                }
            })
        },
        optionUnit(data) {
            this.ruleForm.companyName = data;
            console.log("this", this.ruleForm)
        },
        findDetail() {
            getDetail({
                id: this.updateId
            }).then((res) => {
                if (res.data) {
                    // this.ruleForm.companyId = res.data.companyId
                    console.log("this.ruleForm", this.ruleForm)
                }
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitHandler();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        submitHandler() {
            addPageData({
                type: "ASSETS",
                id: this.updateId,
                companyId: this.ruleForm.companyId,
            }).then((res) => {
                if (res.code === 200) {
                    this.$message.success("操作成功！");
                    this.$emit("close", true);
                } else {
                    this.$message.warning("操作失败！");
                }
            });
        },
        resetForm() {
            this.$emit("close", false);
        },

    },
};
</script>

<style scoped lang="less"></style>