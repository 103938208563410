<template>
    <div class="app-container mini-evaluation-management">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="固定资产编码生成规则" name="first">
                <fixedAssets></fixedAssets>
            </el-tab-pane>
            <el-tab-pane label="易耗品编码生成规则" name="second">
                <consumables></consumables>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import fixedAssets from "./components/fixedAssets.vue"
import consumables from "./components/consumables.vue"
export default {
    components: {
        fixedAssets,
        consumables
    },
    data() {
        return {
            activeName: 'first'
        };
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }
};
</script>
<style scoped lang="less"></style>